import React from "react";
import { Link } from "gatsby";
import * as headerStyles from "../styles/components/headercontent.module.scss";
import classNames from "classnames";

const Headercontent = ({ header }) => {
  let descripWithoutElements = header.brand_section.description.replace(
    /<[^>]*>?/gm,
    ""
  );
  const getLogoType = `logo_${header.banner_image.logo_shade.toLowerCase()}`;
  return (
    <section>
      <div
        className={headerStyles.bannerImage}
        style={{
          backgroundImage: `url(${header.banner_image.banner.url})`,
        }}
      >
        <div className={headerStyles.bannerEocLogo}>
          <img
            className={headerStyles.eocLogo}
            src={header[getLogoType].logo_image.url}
            alt={header[getLogoType].logo_image.title}
            aria-label={header[getLogoType].logo_title}
            width="30%"
          />
        </div>
      </div>
      <div className={headerStyles.bottomContent}>
        <div className={headerStyles.textContent}>
          <h1 className={headerStyles.textTitle}>
            {header.brand_section.primary_title}
          </h1>
          <h1 className={headerStyles.textTitle}>
            {header.brand_section.secondary_title}
          </h1>
          <p className={classNames(headerStyles.textDescription, "p-medium")}>
            {descripWithoutElements}
          </p>
          <Link
            to={header.brand_section.about_us_link.href}
            key="about-us-link"
          >
            <button
              className="button-default btn-dark"
              aria-label="Link to About Us"
            >
              {header.brand_section.about_us_link.title}
            </button>
          </Link>
        </div>
        <div className={headerStyles.brandGrid}>
          {header.brand_grid.map((brand, idx) => (
            <Link
              className={headerStyles.logoContainer}
              to={brand.link.href}
              key={idx}
            >
              <img
                src={brand.brand_logo.logo_image.url}
                alt={brand.brand_logo.logo_image.title}
                aria-label={`${brand.brand_logo.logo_title} Logo`}
                height="90px"
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Headercontent;
