import React, { useState } from "react";
import Layout from "../components/layout";
import Headercontent from "../components/headercontent";
import BrandCarousel from "../components/brandcarousel";
import CallOutSection from "../components/calloutsection";
import Video from "../components/video";
import { SEO } from "../components/seo";
import { graphql, navigate } from "gatsby";
import { getPageByLanguage } from "../hooks/get-page-by-language";
import useCookie from "../hooks/use-cookie";
import { getBrowserLanguage } from "../hooks/get-language";
import { getHeaderAndFooter } from "../hooks/get-header-and-footer";

const IndexPage = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let foundHome = getPageByLanguage(
    data.allContentstackHome.nodes,
    false,
    cookie
  );

  const isBrowser = typeof window !== "undefined";
  if (!foundHome && isBrowser) {
    navigate("/404/");
  }

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <Headercontent header={foundHome} />
      <Video
        title={"Elevate Outdoor Collective"}
        video={foundHome?.external_video}
      />
      <CallOutSection calloutsection={foundHome?.call_out_section[0]} />
      <BrandCarousel carousel={foundHome?.brand_carousel} />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackHome {
      nodes {
        locale
        brand_carousel {
          title
          link {
            href
            title
          }
          background_image {
            url
            title
          }
          global_field {
            logo_title
            logo_image {
              url
              title
            }
          }
        }
        logo_light {
          logo_image {
            title
            url
          }
          logo_title
        }
        logo_dark {
          logo_image {
            title
            url
          }
          logo_title
        }
        banner_image {
          logo_shade
          banner {
            url
            title
          }
        }
        brand_section {
          description
          primary_title
          secondary_title
          about_us_link {
            href
            title
          }
        }
        brand_grid {
          brand_logo {
            logo_image {
              url
              title
            }
            logo_title
          }
          link {
            href
            title
          }
        }
        call_out_section {
          section_title
          card_layout_3
          title_as_link_
          call_out_cards {
            title
            text
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            link {
              href
              title
            }
          }
        }
        external_video {
          video_id
          video_platform
        }
      }
    }
  }
`;

export default IndexPage;
export const Head = () => <SEO />;
